import { usePopupRef } from '@framework/hooks';
import { useCallback } from 'react';
import { classNames } from '@framework/utils';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UserData } from '@user/UserData';

type Props = {
    memberData: UserData;
    setRemovableMemberData: (memberData: UserData) => void;
    disabled: boolean;
};

export const ItemMenuButton: React.FC<Props> = ({ memberData, setRemovableMemberData, disabled }: Props) => {
    const { isOpen, popupRef, handleOpen, handleClose } = usePopupRef<HTMLButtonElement>(false);

    const handleToggleMenuButton = useCallback(() => {
        if (isOpen) {
            handleClose();
        } else {
            handleOpen();
        }
    }, [isOpen, handleOpen, handleClose]);

    const handleRemoveMember = useCallback(() => {
        setRemovableMemberData(memberData);
    }, [memberData, setRemovableMemberData]);

    return (
        <>
            <button
                ref={popupRef}
                className={classNames(
                    'flex h-8 w-8 items-center justify-center rounded-sm border-2 border-gray-500 focus:outline-none',
                    disabled ? 'cursor-not-allowed bg-gray-400' : ''
                )}
                onClick={handleToggleMenuButton}
                style={{ top: '4px', right: '4px' }}
                disabled
            >
                <FontAwesomeIcon icon={faEllipsisH} />
            </button>
            <div
                className={classNames(
                    'absolute z-10 block w-40 rounded-lg border border-gray-500 bg-white py-1 shadow-lg',
                    isOpen ? 'block' : 'hidden'
                )}
                style={{ right: '24px' }}
            >
                <button
                    className={classNames(
                        'flex w-full items-center justify-center py-2 pl-1 text-left text-red-700 hover:bg-indigo-500 hover:text-white'
                    )}
                    onClick={handleRemoveMember}
                >
                    グループから削除
                </button>
            </div>
        </>
    );
};
