import { Link, matchPath, useLocation, useParams } from 'react-router-dom';
import { useGroupEntity } from '@group/hooks';
import { FullScreenLoading } from '@framework/ui';
import { NotFound } from '@framework/ui/pages/NotFound';
import { useMemo } from 'react';
import { UserPagePathBuilder } from '../UserPagePathBuilder';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding } from '@fortawesome/free-regular-svg-icons';
import { useCurrentUserId } from '@framework/auth';
import { UserIconMenu } from '@framework/ui/elements/TopNavigation';
import { GroupId } from '@schema-common/base';
import { classNames } from '@framework/utils';
import { GroupEntity } from '@group/domain';

type LinkProps = {
    to: string;
    title: string;
    isCurrentPage: boolean;
};

const NavigationLink: React.FC<LinkProps> = ({ to, title, isCurrentPage }: LinkProps) => {
    return (
        <Link
            to={to}
            className={classNames(
                'flex-0 mb-1 flex items-center rounded-sm py-1 pl-2 hover:bg-gray-400',
                isCurrentPage ? 'bg-gray-300' : ''
            )}
        >
            {title}
        </Link>
    );
};

type NavProps = {
    groupId: GroupId;
};

const GroupConsoleSideNavigation: React.FC<NavProps> = ({ groupId }: NavProps) => {
    const location = useLocation();

    const { isGroupConsolePage, isGroupConsoleMembersPage } = useMemo(() => {
        return {
            isGroupConsolePage: !!matchPath(location.pathname, {
                path: UserPagePathBuilder.groupConsolePage(groupId),
                exact: true,
            })?.isExact,
            isGroupConsoleMembersPage: !!matchPath(location.pathname, {
                path: UserPagePathBuilder.groupConsoleMembersPage(groupId),
                exact: true,
            })?.isExact,
        };
    }, [location, groupId]);

    return (
        <>
            <NavigationLink
                to={UserPagePathBuilder.groupConsolePage(groupId)}
                title={'グループ設定'}
                isCurrentPage={isGroupConsolePage}
            />
            <NavigationLink
                to={UserPagePathBuilder.groupConsoleMembersPage(groupId)}
                title={'メンバー'}
                isCurrentPage={isGroupConsoleMembersPage}
            />
        </>
    );
};

type Props = {
    childrenWithGroup: (group: GroupEntity) => React.ReactNode;
};

export const WithGroupConsoleMenu: React.FC<Props> = ({ childrenWithGroup }: Props) => {
    const { groupId } = useParams<{ groupId: string }>();
    const { group, loading } = useGroupEntity(groupId);
    const currentUserId = useCurrentUserId();
    const isGroupAdmin = useMemo(() => group?.isAdminRoleOf(currentUserId || ''), [group, currentUserId]);

    if (loading) return <FullScreenLoading />;

    if (!group) return <NotFound />;

    if (!isGroupAdmin)
        return (
            <NotFound
                message="グループの管理者ではないので表示できません。"
                linkTo={UserPagePathBuilder.groupPage(group.id)}
                linkToMessage="グループ画面に戻る"
            />
        );

    return (
        <>
            {/* ヘッダー */}
            <div className="h-16 px-4">
                <div className="flex w-full items-center border-b-2 px-8 py-4">
                    <Link
                        to={UserPagePathBuilder.groupPage(group.id)}
                        className="mr-4 flex max-w-lg items-center truncate text-lg font-bold text-black hover:text-blue-900"
                    >
                        <span className="mr-4">
                            {group.imageUrl ? (
                                <span className="inline-block size-8 overflow-hidden rounded-full align-middle">
                                    <img
                                        className="size-full object-cover"
                                        src={group.imageUrl}
                                        alt={group.name.toString()}
                                    />
                                </span>
                            ) : (
                                <FontAwesomeIcon icon={faBuilding} className="text-brand" />
                            )}
                        </span>
                        {group.name.toString()}
                    </Link>

                    <Link to={UserPagePathBuilder.groupPage(group.id)} className="mr-auto text-brand">
                        ←グループ画面に戻る
                    </Link>

                    <div>
                        <UserIconMenu userId={currentUserId || ''} />
                    </div>
                </div>
            </div>
            <div className="flex">
                {/* サイドメニュー */}
                <div className="mx-8 flex w-64 flex-col pt-4">
                    <GroupConsoleSideNavigation groupId={groupId} />
                </div>
                {/* コンテンツ */}
                <div style={{ height: 'calc(100vh - 64px)' }} className="flex-1 overflow-y-scroll pr-4 pt-4">
                    {childrenWithGroup(group)}
                </div>
            </div>
        </>
    );
};
