import { StickyNodeDescription } from './StickyNodeDescription';
import { StickyNodeDescriptionSet, StickyNodeDescriptionSetJSON } from './StickyNodeDescriptionSet';
import { MapRepository, RTDBPath } from '@framework/repository';
import { StickyNodeDescriptionJSON } from '@schema-app/view-model/contents/{viewModelId}/model-contents/{modelId}/node-descriptions/{nodeId}/StickyNodeDescriptionJSON';
import { ModelId, ViewModelId } from '@schema-common/base';

export class StickyNodeDescriptionSetRepository extends MapRepository<
    StickyNodeDescriptionJSON,
    StickyNodeDescription,
    StickyNodeDescriptionSetJSON,
    StickyNodeDescriptionSet
> {
    static buildFromIds(viewModelId: ViewModelId, modelId: ModelId): StickyNodeDescriptionSetRepository {
        return new this(
            StickyNodeDescription,
            StickyNodeDescriptionSet,
            RTDBPath.Node.descriptionsPath(viewModelId, modelId)
        );
    }
}
