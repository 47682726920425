import { DataSnapshot, RefBuilder, RTDBPath, ServerValue } from '@framework/repository';
import { ReactElement, useEffect } from 'react';
import { BuildInfo } from '@config/build-info';
import { VersionInfoJSON } from '@schema-app/application-config/versions/{version}/VersionInfoJSON';
import { useActionLogSender } from '@framework/action-log';

type Props = {
    children: ReactElement;
};

export const WithVersionInfo: React.FC<Props> = ({ children }: Props) => {
    return (
        <>
            {children}

            {
                // バージョン番号がNaN(Not a Number)の場合には、バージョン情報の保存・監視は行わない
                isNaN(BuildInfo.version) ? null : (
                    <>
                        <StoreVersionInfo />
                        <WatchCurrentVersionInfo />
                    </>
                )
            }
        </>
    );
};

/**
 * バージョン情報を保存する
 */
const StoreVersionInfo: React.FC<unknown> = () => {
    useEffect(() => {
        const ref = RefBuilder.ref(RTDBPath.ApplicationConfig.versionPath(BuildInfo.version));

        ref.get().then((snapshot) => {
            const info = snapshot.val() as VersionInfoJSON | null;
            if (info === null) {
                // バージョン情報が存在しない時には新しく作成する
                const value: VersionInfoJSON = {
                    version: BuildInfo.version,
                    firstLoadedAt: ServerValue.TIMESTAMP,
                    latestLoadedAt: ServerValue.TIMESTAMP,
                    active: true,
                };
                ref.set(value);
            } else if (info.active) {
                // バージョン情報がすでに存在するならば、 latestLoadedAt の値のみを更新する
                ref.child('latestLoadedAt').set(ServerValue.TIMESTAMP);
            }
        });
    }, []);

    return <></>;
};

/**
 * 現在のバージョンのバージョン情報を監視し、 active の値が false に変化した時にリロードを行う
 */
const WatchCurrentVersionInfo: React.FC<unknown> = () => {
    const logSender = useActionLogSender();

    useEffect(() => {
        const ref = RefBuilder.ref(RTDBPath.ApplicationConfig.versionActivePath(BuildInfo.version));
        const onValue = async (snapshot: DataSnapshot) => {
            const value = snapshot.val() as boolean | null;
            if (value === null) return;

            if (value === false) {
                await logSender('global:reload', {
                    currentVersion: BuildInfo.version,
                    cause: 'CurrentVersion Inactivated',
                });
                window.location.reload();
            }
        };

        ref.on('value', onValue);
        return () => ref.off('value');
    }, [logSender]);

    return <></>;
};
