import { ModelElementId } from '@view-model/domain/key';
import { CommandManager, CompositeCommand, ICommand } from '@model-framework/command';
import { ModelElementPositionMap } from '@view-model/domain/model';
import { DragManager, PositionSetRepository } from '@view-model/models/common/PositionSet';
import { ElementsMoveCommand } from '@view-model/command/basic-model/ElementsMoveCommand/ElementsMoveCommand';
import { StickyModelContentsOperation } from '@view-model/adapter';
import { ModelLayout } from '@view-model/models/sticky/layout';
import { StickyModelElementPositionMapRepository } from '@view-model/infrastructure/basic-model/StickyModelElementPositionMapRepository';
import { MultiSelectionMode } from '@user/pages/ViewModelPage';
import { DragContext } from '@model-framework/ui';
import { RTDBPath } from '@framework/repository';
import { ModelId, StickyZoneId, ViewId, ViewModelId } from '@schema-common/base';
import { Rect } from '@view-model/models/common/basic';
import { ViewEntity } from '@view-model/domain/view';
import { getDefaultStore } from 'jotai';
import { dropTargetViewAtom } from './dropTargetViewAtom';
import { ModelCommentContents } from '@view-model/models/sticky/ModelComment';
import { DescriptionPanelCollectionContents } from '@view-model/models/sticky/DescriptionPanel';

export class ElementDragManager {
    private startPositions: ModelElementPositionMap;
    private currentPositions: ModelElementPositionMap;
    private dragging: boolean;
    private readonly modelCommentDragManager: DragManager;
    private readonly descriptionPanelDragManager: DragManager;
    private readonly positionMapRepository: StickyModelElementPositionMapRepository;
    private viewRectOperations?: Record<
        ViewId,
        {
            view: ViewEntity;
            rect?: Rect;
            operation?: StickyModelContentsOperation;
        }
    >;

    constructor(
        viewModelId: ViewModelId,
        modelId: ModelId,
        private readonly viewId: ViewId,
        private readonly commandManager: CommandManager,
        private readonly stickyModelContentsOperation: StickyModelContentsOperation,
        private readonly getViewRectOperations: () => Record<
            ViewId,
            { view: ViewEntity; rect?: Rect; operation?: StickyModelContentsOperation }
        >,
        private readonly selectSingleView: (viewId?: ViewId) => void
    ) {
        this.startPositions = new ModelElementPositionMap();
        this.currentPositions = new ModelElementPositionMap();
        this.positionMapRepository = new StickyModelElementPositionMapRepository(viewModelId, modelId);
        this.modelCommentDragManager = new DragManager(
            new PositionSetRepository(RTDBPath.Comment.positionsPath(viewModelId, modelId))
        );
        this.descriptionPanelDragManager = new DragManager(
            new PositionSetRepository(RTDBPath.DescriptionPanel.positionsPath(viewModelId, modelId))
        );

        this.dragging = false;
    }

    /**
     * ドラッグ開始イベントハンドラ
     *
     * @param id ドラッグ対象のモデル要素ID（マウスポインタで掴んでいるモデル要素）
     * @param multiSelectionMode 複数選択モードかどうか
     */
    onDragStart(id: ModelElementId, multiSelectionMode: MultiSelectionMode): void {
        this.stickyModelContentsOperation.selectForDragStart(id, multiSelectionMode);
        const selectedIds = this.stickyModelContentsOperation.getSelectedIds();

        this.startPositions = this.stickyModelContentsOperation.selectedModelElementPositionMap();
        this.currentPositions = this.startPositions;

        this.descriptionPanelDragManager.dragStart(selectedIds).then();
        this.modelCommentDragManager.dragStart(selectedIds).then();

        if (this.dragging) {
            console.warn(
                'NodeDragManager.onDragStart() is called multiple times before onDragEnd(). \
                It may cause an unpredictable problem.'
            );
        }
        this.dragging = true;
        this.viewRectOperations = this.getViewRectOperations();
    }

    async onDrag(context: DragContext): Promise<void> {
        this.currentPositions = this.startPositions.movePosition(
            context.x - context.dragStartX,
            context.y - context.dragStartY
        );

        const target = this.findDropTargetView(context);
        const rect = this.viewRectOperations?.[this.viewId].rect ?? undefined;
        const canDropIntoZone = this.stickyModelContentsOperation.getDroppableSelectedIdsIntoZone().length > 0;
        const stickyZoneId = target && rect && canDropIntoZone ? this.findDropTargetZone(rect, target) : undefined;
        const dropTarget = target ? { viewId: target.view.id, stickyZoneId } : null;
        getDefaultStore().set(dropTargetViewAtom, dropTarget);

        await Promise.all([
            this.positionMapRepository.savePositions(this.currentPositions),
            this.modelCommentDragManager.drag(context.x, context.y, context.dragStartX, context.dragStartY),
            this.descriptionPanelDragManager.drag(context.x, context.y, context.dragStartX, context.dragStartY),
        ]);
    }

    async onDragEnd(context: DragContext): Promise<void> {
        if (!this.dragging) {
            // Example issue: https://github.com/levii/balus-app/issues/282
            console.warn(
                'NodeDragManager.onDragEnd() is called multiple times after single onDragStart(). \
                    It may cause an unpredictable problem.'
            );
        }
        this.dragging = false;
        getDefaultStore().set(dropTargetViewAtom, null);

        // モデル要素(付箋、ゾーン) と、コメントの位置をレイアウトにスナップさせて、保存する
        this.currentPositions = this.currentPositions.snapToLayout(ModelLayout);
        await this.positionMapRepository.savePositions(this.currentPositions);

        const target = this.findDropTargetView(context);
        const rect = target ? this.viewRectOperations?.[this.viewId].rect : undefined;

        if (rect && target) {
            const selectedElements = this.stickyModelContentsOperation.getSelectedElementsIncludeZoneContents();

            // 選択中の要素を複製して、移動先のビューに合わせて位置を調整する
            const cloned = selectedElements
                .cloneNew(Object.fromEntries(selectedElements.assetUrls().map((url) => [url, url])))
                .move(-(target.rect.position.x - rect.position.x), -(target.rect.position.y - rect.position.y));

            const descriptionPanelStartPositions = this.descriptionPanelDragManager.getStartPositions();
            const modelCommentStartPositions = this.modelCommentDragManager.getStartPositions();

            // ドラッグ開始前の位置を復元する(Undoしたときに元の位置に戻せるようにするため)
            this.positionMapRepository.savePositions(this.startPositions).then();
            this.modelCommentDragManager.dragEnd({ restorePositions: true }).then();
            this.descriptionPanelDragManager.dragEnd({ restorePositions: true }).then();
            this.stickyModelContentsOperation.panelContents = (() => {
                const base = this.stickyModelContentsOperation.panelContents;

                const positionSet = descriptionPanelStartPositions.all().reduce((set, [id, position]) => {
                    return set.set(id, position);
                }, base.positions);

                return new DescriptionPanelCollectionContents(base.panels, positionSet);
            })();
            this.stickyModelContentsOperation.commentContents = (() => {
                const base = this.stickyModelContentsOperation.commentContents;

                const positionSet = modelCommentStartPositions.all().reduce((set, [id, position]) => {
                    return set.set(id, position);
                }, base.positions);

                return new ModelCommentContents(base.modelKey, base.threads, positionSet);
            })();

            const deleteCommand = await this.stickyModelContentsOperation.buildDeleteSelectedElementsCommand({
                includeZoneContents: true,
            });

            const canDropIntoZone = this.stickyModelContentsOperation.getDroppableSelectedIdsIntoZone().length > 0;

            const command = CompositeCommand.composeOptionalCommands(
                deleteCommand,
                target.operation.buildCreateElementsCommandFromOtherModel(
                    cloned,
                    canDropIntoZone ? this.findDropTargetZone(rect, target) : undefined
                )
            );

            if (command) {
                this.commandManager.execute(command);
                this.selectSingleView(target.view.id);
                target.operation.addToSelectionMany(cloned.elementIds());
            }
        } else {
            // 全ての要素コマンドが null, undefined の場合には、 composeOptionalCommands() は null を返す
            const command = CompositeCommand.composeOptionalCommands(
                // モデル要素(付箋、ゾーン)の位置移動
                this.buildElementsMoveCommand(),
                // モデルコメントの位置移動
                await this.modelCommentDragManager.dragEnd(),
                // ゾーンに含まれる要素の更新 (親子関係の更新)
                await this.stickyModelContentsOperation.buildSelectedElementsDropCommand(),
                // 説明パネルの位置移動
                await this.descriptionPanelDragManager.dragEnd()
            );

            // ドラッグの開始前・後でいずれかの要素に差分があれば、 Undo できるように CommandManager 経由で実行する
            if (command) {
                this.commandManager.execute(command);
            }
        }

        this.viewRectOperations = undefined;
    }

    /**
     * ドロップ先のビューがある場合に、ドロップ先のゾーンがあるか探す
     */
    private findDropTargetZone(
        currentViewRect: Rect,
        targetView: { view: ViewEntity; rect: Rect; operation: StickyModelContentsOperation }
    ): StickyZoneId | undefined {
        const rect = this.stickyModelContentsOperation.getDroppingRect();
        if (!rect) {
            return;
        }

        // ドロップ先のビューの座標系に変換
        const translatedRect = rect.movePosition(
            currentViewRect.position.x - targetView.rect.position.x,
            currentViewRect.position.y - targetView.rect.position.y
        );
        const zone = targetView.operation.findDropTargetZoneByRect(translatedRect);

        return zone?.id ?? undefined;
    }

    /**
     * 付箋モデル要素(付箋、ゾーン)の位置移動コマンドを（必要な場合には）生成して返す
     *
     * @private
     */
    private buildElementsMoveCommand(): ICommand | undefined {
        // ドラッグ移動の開始前と後で位置が変化していなければ、コマンドは生成しない
        if (this.startPositions.isEqual(this.currentPositions)) {
            return;
        }

        return new ElementsMoveCommand(this.startPositions, this.currentPositions, this.positionMapRepository);
    }

    /**
     * カーソルの位置から移動先のビューがあるかを探す
     */
    private findDropTargetView(
        context: DragContext
    ): { view: ViewEntity; rect: Rect; operation: StickyModelContentsOperation } | undefined {
        const viewRectOperations = this.viewRectOperations;
        const rect = viewRectOperations?.[this.viewId]?.rect;
        if (!rect || !viewRectOperations) {
            return;
        }

        const mousePosition = {
            x: context.x + rect.position.x,
            y: context.y + rect.position.y,
        };

        if (rect.includePosition(mousePosition)) {
            return;
        }

        // マウスポインタがビューの外にある場合、別のビューに移動できないか計算してみる
        const target = Object.values(viewRectOperations).find((viewRectOperation) => {
            const { rect } = viewRectOperation;
            return rect && viewRectOperation.view.id !== this.viewId && rect.includePosition(mousePosition);
        });

        if (!target?.rect || !target?.operation) {
            return;
        }

        return {
            rect: target.rect,
            view: target.view,
            operation: target.operation,
        };
    }
}
