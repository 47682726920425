import { getRealtimeDatabaseCurrentTimestamp } from '@framework/firebase/rtdb';
import { RTDBPath, RefBuilder, ServerValue, UpdateUtil } from '@framework/repository';
import { GroupId, UserId } from '@schema-common/base';
import { GroupAnalysisSetting } from './GroupAnalysisSetting';
import { GroupEntity } from './GroupEntity';
import { createGroupEntityRepository } from './GroupEntityRepository';
import { GroupMemberRole } from './GroupMemberRole';
import { GroupMemberSignUpMethod } from './GroupMemberSignUpMethod';
import { OperatorUserJSON } from '@schema-app/admin/operator-users/{operatorUserId}/OperatorUserJSON';
import { WorkspaceOperation } from '@workspace/domain/WorkspaceOperation';
import { GroupMemberInvitationBuilder, GroupMemberInvitationOperation } from '../GroupMemberInvitation';
import { UserData } from '@user/UserData';

export class GroupOperation {
    /**
     * グループを新しく作成します。
     * 作成に成功した時にはグループIDを、失敗した時には null を返します。
     *
     * OperatorUser が利用可能なメソッドです。
     *
     * @param name グループ名
     * @param email 招待するメールアドレス
     * @returns {String | Null} 作成したグループID
     */
    static async create(name: string, email: string): Promise<GroupId | null> {
        const now = await getRealtimeDatabaseCurrentTimestamp();
        const groupEntity = GroupEntity.buildNew(name, now);

        const message = '';
        const baseUrl = new URL('/', window.location.href)
            .toString()
            .replace('admin.', '')
            .replace('admin-dot-', '')
            .replace('localhost:9098', 'localhost:9082')
            .replace('localhost:9198', 'localhost:9182');

        const builder = new GroupMemberInvitationBuilder(
            groupEntity,
            UserData.AdminConsoleUser,
            [email],
            'admin',
            message,
            baseUrl
        );

        try {
            await RefBuilder.ref().update({
                [RTDBPath.Group.groupPath(groupEntity.id)]: groupEntity.dump(),
                ...builder.buildUpdates(now),
            });

            return groupEntity.id;
        } catch (e) {
            console.error(e);
        }

        return null;
    }

    /**
     * 指定のグループの名前と新規登録時に利用可能なログイン方法を更新します。
     * 実行の成否を boolean で返します(成功時に true)。
     *
     * 対象グループの管理者ロールを保持したユーザが利用可能なメソッドです。
     *
     * @param groupId 更新対象のグループID
     * @param name 変更後のグループ名
     * @param signUpMethods 変更後の新規登録時に利用可能なログイン方法
     * @param analysisSetting 分析機能の有効/無効
     * @param imageUrl グループアイコン
     * @returns 更新の成否
     */
    static async update(
        groupId: GroupId,
        name: string,
        signUpMethods: GroupMemberSignUpMethod,
        analysisSetting: GroupAnalysisSetting,
        imageUrl: string | null
    ): Promise<boolean> {
        const now = await getRealtimeDatabaseCurrentTimestamp();

        return new Promise((resolve) => {
            RefBuilder.ref().update(
                {
                    [RTDBPath.Group.namePath(groupId)]: name,
                    [RTDBPath.Group.settingAvailableSignUpMethodsPath(groupId)]: signUpMethods.dump(),
                    [RTDBPath.Group.settingAnalysisSettingPath(groupId)]: analysisSetting.dump(),
                    [RTDBPath.Group.imageUrlPath(groupId)]: imageUrl,
                    [RTDBPath.Group.updatedAtPath(groupId)]: now.toUnixTimestamp(),
                },
                (err) => {
                    if (err) console.error(err);
                    resolve(!err);
                }
            );
        });
    }

    /**
     * 指定のグループの指定ユーザのロールを変更します。
     *
     * 対象グループの管理者ロールを保持したユーザが利用可能なメソッドです。
     * 自分自身のロールを変更することはできません。
     *
     * @param groupId 更新対象のグループID
     * @param userId 更新対象のユーザID
     * @param role 変更後のグループメンバーロール
     * @returns 更新の成否
     */
    static async updateMemberRole(groupId: GroupId, userId: UserId, role: GroupMemberRole): Promise<boolean> {
        return new Promise((resolve) => {
            RefBuilder.ref().update(
                {
                    [RTDBPath.Group.memberPath(groupId, userId)]: role,
                },
                (err) => {
                    if (err) console.error(err);
                    resolve(!err);
                }
            );
        });
    }

    /**
     * グループから指定した複数のメンバーを削除します。
     * 所属しているワークスペースがある場合には、それらのワークスペースからもメンバーが削除されます。
     * 削除の成否を boolean で返します(成功時に true)。
     *
     * 対象グループの管理者ロールを保持したユーザが利用可能なメソッドです。
     *
     * 仕様:
     *   メンバー削除時には以下の4つのパスの値を削除します。
     *   1. workspaces/{workspaceKey}/members/{userKey}
     *   2. workspace/assigned-workspace-index/{userId}/{groupId}/{workspaceId}
     *   3. groups/{groupKey}/members/{userKey}
     *   4. group/assigned-group-index/{userId}/{groupId}
     */
    static async removeMembers(groupId: GroupId, userIds: UserId[]): Promise<boolean> {
        try {
            /**
             * 削除対象の userId, workspaceId ごとに以下のパスを作成する
             *   1. workspaces/{workspaceKey}/members/{userKey}
             *   2. workspace/assigned-workspace-index/{userId}/{groupId}/{workspaceId}
             */
            const userWorkspaceArray = await Promise.all(
                userIds.map(async (userId) => {
                    const snapshot = await RefBuilder.ref(
                        RTDBPath.Workspace.assignedGroupWorkspaceIndexPath(userId, groupId)
                    ).get();
                    const workspaceIds = Object.keys(snapshot.val() || {});

                    return workspaceIds.reduce(
                        (result: Record<string, null>, workspaceId) => ({
                            ...result,
                            [RTDBPath.Workspace.memberRolePath(workspaceId, userId)]: null,
                            [RTDBPath.Workspace.assignedGroupWorkspaceIndexValuePath(userId, groupId, workspaceId)]:
                                null,
                        }),
                        {}
                    );
                })
            );

            const userWorkspaceRecords = userWorkspaceArray.reduce((result, currentValue) => {
                return {
                    ...result,
                    ...currentValue,
                };
            }, {});

            /**
             * 削除対象の userId ごとに以下のパスを作成する
             *   3. groups/{groupKey}/members/{userKey}
             *   4. group/assigned-group-index/{userId}/{groupId}
             */
            const userGroupRecords = userIds.reduce(
                (result: Record<string, null>, userId) => ({
                    ...result,
                    [RTDBPath.Group.memberPath(groupId, userId)]: null,
                    [RTDBPath.Group.assignedGroupIndexValuePath(userId, groupId)]: null,
                }),
                {}
            );

            // 上記で作ったパスを一括削除
            await RefBuilder.ref().update({
                ...userGroupRecords,
                ...userWorkspaceRecords,
            });

            return true;
        } catch (err) {
            console.error(err);
            return false;
        }
    }

    /**
     * グループの論理削除を行います。
     *
     * * グループ配下の全てのワークスペースを論理削除します
     * * グループに所属する全てのメンバーをグループから削除します
     * * 削除日、削除ユーザIDを記録します
     */
    static async logicalDelete(groupId: GroupId, trashedBy: OperatorUserJSON['email']): Promise<boolean> {
        const group = await createGroupEntityRepository(groupId).get();
        if (!group) return false;
        // 既に論理削除済みならば、何も処理せずに論理削除失敗を返す
        if (group.trashedAt) return false;

        // グループへのメンバー招待状を無効化する
        await GroupMemberInvitationOperation.allDeactivate(groupId);

        // 全てのワークスペースを論理削除する
        const workspaceIdsSnapshot = await RefBuilder.ref(RTDBPath.Group.workspaceIndexPath(groupId)).get();
        const workspaceIds = Object.keys(workspaceIdsSnapshot.val() || {});
        await Promise.all(workspaceIds.map((workspaceId) => WorkspaceOperation.logicalDelete(workspaceId, trashedBy)));

        // 更新日、削除日、削除ユーザIDを設定
        const result = await new Promise((resolve) =>
            RefBuilder.ref().update(
                UpdateUtil.prependPath(RTDBPath.Group.groupPath(group.id), {
                    updatedAt: ServerValue.TIMESTAMP,
                    trashedAt: ServerValue.TIMESTAMP,
                    trashedBy: trashedBy,
                }),
                (err) => {
                    if (err) {
                        console.error(err);
                        resolve(false);
                    } else {
                        resolve(true); // success
                    }
                }
            )
        );
        if (!result) {
            return false;
        }

        // 全てのユーザをグループから除外
        return this.removeMembers(group.id, group.members.userIds());
    }
}
