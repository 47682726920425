import { useCallback } from 'react';
import { GroupOperation } from '@group/domain/GroupEntity/GroupOperation';
import { toast } from 'react-hot-toast';
import { GroupId, UserId } from '@schema-common/base';
import { GroupMemberRole } from '@group/domain';
import { classNames } from '@framework/utils';

type Props = {
    userId: UserId;
    groupId: GroupId;
    defaultRole: GroupMemberRole;
    disabled: boolean;
};

export const RoleSelect: React.FC<Props> = ({ groupId, userId, defaultRole, disabled }: Props) => {
    const handleroleChange = useCallback(
        async (e: React.ChangeEvent<HTMLSelectElement>) => {
            if (await GroupOperation.updateMemberRole(groupId, userId, GroupMemberRole.load(e.target.value))) {
                toast.success('ロールを更新しました');
            } else {
                toast.error('ロールの更新に失敗しました');
            }
        },
        [groupId, userId]
    );

    return (
        <select
            className={classNames('form-select m-1 py-1 text-sm', disabled ? 'cursor-not-allowed bg-gray-400' : '')}
            defaultValue={defaultRole}
            onChange={handleroleChange}
            disabled
        >
            {GroupMemberRole.values().map((role) => (
                <option key={role} value={role}>
                    {GroupMemberRole.getLocalString(role)}
                </option>
            ))}
        </select>
    );
};
