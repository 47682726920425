import { GroupId, GroupMemberInvitationId } from '@schema-common/base';
import { ViewModelAssetURL } from './urls';

export class UserPagePathBuilder {
    /**
     * ログインページ
     */
    static loginPage(requestPath?: string): string {
        const path = '/login';
        if (requestPath) {
            const params = new URLSearchParams();
            params.set('requestPath', requestPath);
            return `${path}?${params.toString()}`;
        }
        return path;
    }

    /**
     * OAuthログイン後のコールバックURL
     */
    static oauthCallbackPage(): string {
        return '/user/auth/callback';
    }

    /**
     * ユーザ登録ページ
     */
    static signUpPage(): string {
        return '/user/signUp';
    }

    /**
     * ログアウトページ
     */
    static logoutPage(): string {
        return '/logout';
    }

    /**
     * パスワード再設定ページ
     */
    static passwordResetPage(): string {
        return '/user/passwordReset';
    }

    /**
     * 既存ユーザに対するアカウント連携完了の案内ページ
     */
    static accountConnectedPage(): string {
        return '/user/accountConnected';
    }

    static groupPage(groupId: string): string {
        return `/groups/${groupId}`;
    }

    static groupConsolePage(groupId: string): string {
        return `/groups/${groupId}/console`;
    }

    static groupConsoleMembersPage(groupId: string): string {
        return `${this.groupConsolePage(groupId)}/members`;
    }

    static groupMemberInvitationPage(): string {
        return '/group-member-invitations';
    }

    /**
     * グループへのメンバー招待を承諾するURL
     * NOTE: Cloud Functions 側でもこのURLを組み立てている箇所があるので、変更する際には注意すること。
     */
    static buildGroupMemberInvitationUrl(
        groupId: GroupId,
        invitationId: GroupMemberInvitationId,
        baseUrl = window.location.href
    ): string {
        const url = new URL(this.groupMemberInvitationPage(), baseUrl);

        url.searchParams.set('groupId', groupId);
        url.searchParams.set('invitationId', invitationId);

        return url.toString();
    }

    static workspacePage(workspaceId: string, scrollTargetFolderId?: string): string {
        const path = `/workspaces/${workspaceId}`;
        if (!scrollTargetFolderId) return path;

        const params = new URLSearchParams();
        params.set('scrollTargetFolderId', scrollTargetFolderId);
        return `${path}?${params.toString()}`;
    }

    /**
     * 古い形式のビューモデルURLパス。
     *
     */
    static legacyViewModelPage(workspaceId: string, viewModelId: string): string {
        return `/workspaces/${workspaceId}/view-models/${viewModelId}`;
    }

    /**
     * 新しい形式のビューモデルURLパス。
     *
     * @param viewModelId
     * @returns
     */
    static viewModelPage(viewModelId: string): string {
        return `/view-models/${viewModelId}`;
    }

    /**
     * ビューモデルに貼付されたmedia assetのパス
     * @param groupId
     * @param viewModelId
     * @param assetId
     * @returns
     */
    static viewModelAssetPath(groupId: string, viewModelId: string, assetId: string): string {
        return new ViewModelAssetURL(groupId, viewModelId, assetId).path;
    }

    /**
     * KATA MAPへのビューモデルURLを返す。
     *
     * @returns
     */
    static kataMapPage(source: 'sidebar' | 'menu'): string {
        return `https://levii.notion.site/KATA-Map-2496e6221d49487e9cd608f3dfcfd552?source=${source}`;
    }
}
