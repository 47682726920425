import { StickyNodeDescription } from './StickyNodeDescription';
import { ObjectRepository, RTDBPath } from '@framework/repository';
import { StickyNodeDescriptionJSON } from '@schema-app/view-model/contents/{viewModelId}/model-contents/{modelId}/node-descriptions/{nodeId}/StickyNodeDescriptionJSON';
import { ModelId, NodeId, ViewModelId } from '@schema-common/base';

export class StickyNodeDescriptionRepository extends ObjectRepository<
    StickyNodeDescriptionJSON,
    StickyNodeDescription
> {
    static buildFromIds(viewModelId: ViewModelId, modelId: ModelId, nodeId: NodeId): StickyNodeDescriptionRepository {
        return new this(StickyNodeDescription, RTDBPath.Node.descriptionPath(viewModelId, modelId, nodeId));
    }
}
