import { ClipboardPayload } from '@model-framework/clipboard';
import { ViewCollection } from '@view-model/domain/view';
import { ModelContentsCollection, StickyModelContentsJSON } from '@view-model/domain/model';
import { ViewJSON } from '@schema-app/view-model/contents/{viewModelId}/views/{viewId}/ViewJSON';
import { ViewModelId } from '@schema-common/base';
import { PositionSet, PositionSetJSON } from '@view-model/models/common/PositionSet';
import { nanoid } from 'nanoid';
import { ConsistencyLinkCollection, ConsistencyLinkCollectionJSON } from '@view-model/domain/ConsistencyLink';

export type ViewsClipboardPayload = ClipboardPayload & {
    id: string;
    target: 'ViewModel';
    contentType: 'Views';
    sourceViewModelId: ViewModelId; // コピー元のビューモデルID
    views: ViewJSON[];
    viewPositions: PositionSetJSON;
    modelContents: StickyModelContentsJSON[];
    consistencyLinks: ConsistencyLinkCollectionJSON;
};

export namespace ViewsClipboardPayload {
    export function fromViewsAndModelContents(args: {
        sourceViewModelId: ViewModelId;
        views: ViewCollection;
        viewPositions: PositionSet;
        modelContents: ModelContentsCollection;
        consistencyLinks?: ConsistencyLinkCollection | null;
    }): ViewsClipboardPayload {
        const { sourceViewModelId, views, viewPositions, modelContents, consistencyLinks } = args;
        return {
            id: `${nanoid()}`,
            target: 'ViewModel',
            contentType: 'Views',
            sourceViewModelId,
            views: views.dump(),
            viewPositions: viewPositions.dump(),
            modelContents: modelContents.dump(),
            consistencyLinks: consistencyLinks ? consistencyLinks.dump() : {},
        };
    }
}

export const isViewsClipboardPayload = (payload: ClipboardPayload): payload is ViewsClipboardPayload =>
    payload.target === 'ViewModel' && payload.contentType === 'Views';
