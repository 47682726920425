import { RootFolderTree } from '@workspace/view-model-folder/domain/FolderTree';
import { Folder, FolderCollection } from '@workspace/view-model-folder/domain/Folder';
import { ViewModelCollection } from '@workspace/view-model-folder/domain/ViewModelCollection';
import { RootFolderContainer } from '@workspace/view-model-folder/domain/RootFolderContainer';
import { RootFolderTreeRepository } from '@workspace/view-model-folder/infrastructure/FolderTree';
import { FolderId, ViewModelId, WorkspaceId } from '@schema-common/base';
import { ObjectRepository, RTDBPath } from '@framework/repository';
import { ViewModelEntity } from '@view-model/domain/view-model';
import { ViewModelJSON } from '@schema-app/view-model/entities/{viewModelId}/ViewModelJSON';
import { FolderJSON } from '@schema-app/workspace-contents/{workspaceKey}/folders/{folderId}/FolderJSON';

export class ViewModelFolderAdapter {
    private readonly rootFolderTreeRepository: RootFolderTreeRepository;
    private readonly viewModelRepos: Record<ViewModelId, ObjectRepository<ViewModelJSON, ViewModelEntity>> = {};
    private readonly folderRepos: Record<FolderId, ObjectRepository<FolderJSON, Folder>> = {};

    private tree = RootFolderTree.buildEmpty();
    private viewModels = ViewModelCollection.buildEmpty();
    private folders = FolderCollection.buildEmpty();

    constructor(private readonly workspaceId: WorkspaceId) {
        this.rootFolderTreeRepository = new RootFolderTreeRepository(workspaceId);
    }

    private buildFolderContainer(): RootFolderContainer {
        return new RootFolderContainer(this.workspaceId, this.tree, this.folders, this.viewModels);
    }

    onMount(onUpdate: (container: RootFolderContainer) => void): void {
        this.rootFolderTreeRepository.on((tree) => {
            // 新旧のツリーの ViewModelId を利用して必要に応じてリスナーの追加・削除を行います
            this.listenViewModels(this.tree.getAllViewModelIds(), tree.getAllViewModelIds(), () =>
                onUpdate(this.buildFolderContainer())
            );
            this.listenFolders(this.tree.getAllFolderIds(), tree.getAllFolderIds(), () => {
                onUpdate(this.buildFolderContainer());
            });

            this.tree = tree;
            onUpdate(this.buildFolderContainer());
        });
    }

    onUnmount(): void {
        this.rootFolderTreeRepository.off();

        Object.values(this.viewModelRepos).forEach((repo) => repo.removeListener());
        Object.values(this.folderRepos).forEach((repo) => repo.removeListener());
    }

    private listenViewModels(previousIds: ViewModelId[], currentIds: ViewModelId[], onUpdate: () => void): void {
        const removedIds = previousIds.filter((id) => !currentIds.includes(id));
        const addedIds = currentIds.filter((id) => !previousIds.includes(id));

        addedIds.forEach((id) => {
            const repo = new ObjectRepository(ViewModelEntity, RTDBPath.ViewModel.viewModelPath(id));
            repo.addListener((entity) => {
                if (entity) {
                    this.viewModels = this.viewModels.add(entity);
                } else {
                    this.viewModels = this.viewModels.remove(id);
                }

                onUpdate();
            });
            this.viewModelRepos[id] = repo;
        });

        removedIds.forEach((id) => {
            this.viewModels = this.viewModels.remove(id);
            this.viewModelRepos[id]?.removeListener();
        });
        onUpdate(); // removedIds の処理が全体が終わってから onUpdate を呼ぶ
    }

    private listenFolders(previousIds: FolderId[], currentIds: FolderId[], onUpdate: () => void): void {
        const removedIds = previousIds.filter((id) => !currentIds.includes(id));
        const addedIds = currentIds.filter((id) => !previousIds.includes(id));

        addedIds.forEach((id) => {
            const repo = new ObjectRepository(Folder, RTDBPath.Workspace.folderPath(id));
            repo.addListener((entity) => {
                if (entity) {
                    this.folders = this.folders.add(entity);
                } else {
                    this.folders = this.folders.remove(id);
                }

                onUpdate();
            });
            this.folderRepos[id] = repo;
        });

        removedIds.forEach((id) => {
            this.folders = this.folders.remove(id);
            this.folderRepos[id]?.removeListener();
        });
        onUpdate(); // removedIds の処理が全体が終わってから onUpdate を呼ぶ
    }
}
