import { RootFolderTree } from '@workspace/view-model-folder/domain/FolderTree';
import { FolderId, ViewModelId, WorkspaceId } from '@schema-common/base';
import { DataSnapshot, RefBuilder, Reference, RTDBPath } from '@framework/repository';
import { RootFolderTreeJSON } from '@schema-app/workspace-contents/{workspaceKey}/root-folder-tree/RootFolderTreeJSON';

type UpdateValues = Record<string, string | null>;

export class RootFolderTreeRepository {
    private readonly ref: Reference;
    private readonly newRef: Reference;
    private callback: ((value: DataSnapshot) => void) | undefined = undefined;

    constructor(workspaceId: WorkspaceId) {
        this.ref = RefBuilder.ref(RTDBPath.Workspace.rootFolderTreePath(workspaceId));
        this.newRef = RefBuilder.ref(RTDBPath.Workspace.newRootFolderTreePath(workspaceId));
    }

    async load(): Promise<RootFolderTree> {
        const snapshot = await this.newRef.once('value');
        const j = snapshot.val() as RootFolderTreeJSON;
        return j ? RootFolderTree.load(j) : RootFolderTree.buildEmpty();
    }

    on(callback: (rootFolderTree: RootFolderTree) => void): void {
        this.callback = this.newRef.on('value', (snapshot) => {
            const j = snapshot.val() as RootFolderTreeJSON;
            callback(j ? RootFolderTree.load(j) : RootFolderTree.buildEmpty());
        });
    }

    off(): void {
        if (this.callback) {
            this.newRef.off('value', this.callback);
            this.callback = undefined;
        }
    }

    private folderPath(folderId: FolderId): string {
        return ['folderIdMap', folderId].join('/');
    }

    private viewModelPath(viewModelId: ViewModelId): string {
        return ['viewModelIdMap', viewModelId].join('/');
    }

    private async update(values: UpdateValues): Promise<void> {
        await this.ref.update(values);
        await this.newRef.update(values);
    }

    async addFolder(parentFolderId: FolderId, folderId: FolderId): Promise<void> {
        await this.addFolderMany(parentFolderId, [folderId]);
    }

    async addFolderMany(parentFolderId: FolderId, folderIds: FolderId[]): Promise<void> {
        const updates: UpdateValues = {};

        folderIds.forEach((id) => {
            updates[this.folderPath(id)] = parentFolderId;
        });

        await this.update(updates);
    }

    async addViewModel(parentFolderId: FolderId, viewModelId: ViewModelId): Promise<void> {
        await this.addViewModelMany(parentFolderId, [viewModelId]);
    }

    async addViewModelMany(parentFolderId: FolderId, viewModelIds: ViewModelId[]): Promise<void> {
        const updates: UpdateValues = {};

        viewModelIds.forEach((id) => {
            updates[this.viewModelPath(id)] = parentFolderId;
        });

        await this.update(updates);
    }

    async moveFolder(folderId: FolderId, targetFolderId: FolderId): Promise<void> {
        const updates: UpdateValues = {};
        updates[this.folderPath(folderId)] = targetFolderId;
        await this.update(updates);
    }

    async moveViewModel(viewModelId: ViewModelId, targetFolderId: FolderId): Promise<void> {
        const updates: UpdateValues = {};
        updates[this.viewModelPath(viewModelId)] = targetFolderId;
        await this.update(updates);
    }

    async removeFolder(folderId: FolderId): Promise<void> {
        const updates: UpdateValues = {};

        // 現時点のツリーを取得して、削除対象のフォルダを取得する
        const tree = await this.load();
        const folder = tree.findFolder(folderId);

        // 削除対象のフォルダが見つからなければ、何もする必要が無い
        if (!folder) {
            return;
        }

        // 削除対象フォルダと、その子孫のフォルダとビューモデルを全てツリーから取り除く
        updates[this.folderPath(folderId)] = null;
        folder.descendantsFolderIds().forEach((id) => {
            updates[this.folderPath(id)] = null;
        });
        folder.descendantsViewModelIds().forEach((id) => {
            updates[this.viewModelPath(id)] = null;
        });

        await this.update(updates);
    }

    async removeViewModel(viewModelId: ViewModelId): Promise<void> {
        const updates: UpdateValues = {};
        updates[this.viewModelPath(viewModelId)] = null;
        await this.update(updates);
    }
}
