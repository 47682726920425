import { ModelKey, ViewKey } from '@view-model/domain/key';
import { ViewEntity } from '@view-model/domain/view/ViewEntity';
import { ViewJSON } from '@schema-app/view-model/contents/{viewModelId}/views/{viewId}/ViewJSON';
import { ViewId } from '@schema-common/base';
import { PositionSet } from '@view-model/models/common/PositionSet';
import { Rect } from '@view-model/models/common/basic';
import { Position } from '@view-model/models/common/types/ui';

export class ViewCollection {
    constructor(private readonly views: ViewEntity[]) {}

    static buildEmpty(): ViewCollection {
        return new ViewCollection([]);
    }

    public addViews(views: ViewEntity[]): ViewCollection {
        return views.reduce<ViewCollection>((acc, view) => {
            return acc.added(view);
        }, this);
    }

    isEmpty(): boolean {
        return this.views.length === 0;
    }

    /**
     * ソートされたViewEntityのリストを返す
     */
    public backToFront(): ViewCollection {
        const views = [...this.views].sort((a: ViewEntity, b: ViewEntity) => a.compareTo(b));
        return new ViewCollection(views);
    }

    /**
     * backToFront() とは逆順にソートされたViewEntityのリストを返す
     *
     * 手前のビューが先に来るリストが必要な場合はこちらを使う。
     */
    public frontToBack(): ViewCollection {
        const views = [...this.backToFront().views];
        return new ViewCollection(views.reverse());
    }

    public dump(): ViewJSON[] {
        return this.backToFront().views.map((view) => view.dump());
    }

    public static load(dump: ViewJSON[]): ViewCollection {
        return new ViewCollection(dump.map((view) => ViewEntity.load(view)));
    }

    public cloneNew(
        modelKeyMap: Record<string, ModelKey>
    ): [ViewCollection, Record<string, ViewKey>, Record<string, string>] {
        const views: ViewEntity[] = [];
        const keyMap: Record<string, ViewKey> = {};
        const idMap: Record<string, string> = {};
        this.views.forEach((view: ViewEntity) => {
            const newView = view.cloneNew(modelKeyMap);
            keyMap[view.key.toString()] = newView.key;
            idMap[view.id] = newView.id;
            views.push(newView);
        });
        return [new ViewCollection(views), keyMap, idMap];
    }

    /**
     * 位置情報が存在するビューのみのコレクションを返します
     * @param positions
     */
    filterByPositionSet(positions: PositionSet): ViewCollection {
        const views = this.views.filter((view) => !!positions.find(view.id));

        return new ViewCollection(views);
    }

    public correctOrder(start: number): ViewCollection {
        let order = start;
        const views = this.views.map((view: ViewEntity) => (view.order ? view : view.withOrder(order++)));

        return new ViewCollection(views);
    }

    public includeKey(key: ViewKey): boolean {
        return this.views.some((view: ViewEntity) => view.key.isEqual(key));
    }

    findById(viewId: ViewId): ViewEntity | undefined {
        return this.views.find((view) => view.key.id === viewId);
    }

    allIds(): ViewId[] {
        return this.views.map((view) => view.id);
    }

    public added(newView: ViewEntity): ViewCollection {
        // CollectionEventsMixinのadd()のデフォルト挙動を踏襲して、すでに要素があった場合は置き換えるようにする
        const views = [...this.views.filter((view) => !view.key.isEqual(newView.key)), newView];
        return new ViewCollection(views);
    }

    public removed(deletedViewKey: ViewKey): ViewCollection {
        const views = this.views.filter((view) => !view.key.isEqual(deletedViewKey));
        return new ViewCollection(views);
    }

    /**
     * ビューを選択状態も考慮して適切な並び順(背面から手前の順)で返す
     */
    bringSelectedToFront(selectedViewIds: Set<ViewId>): ViewCollection {
        // 選択されていない場合はそのまま返す
        if (selectedViewIds.size <= 0) return new ViewCollection(this.views);

        // 選択されているビューを全面に回す
        const selectedViews = this.views.filter((view) => selectedViewIds.has(view.id));
        const nonSelectedViews = this.views.filter((view) => !selectedViewIds.has(view.id));

        return new ViewCollection([...nonSelectedViews, ...selectedViews]);
    }

    /**
     * View IDをキー、ViewEntityのダンプを値としたRecord型の値を返します。
     */
    toDumpRecord(): Record<ViewId, ViewJSON> {
        const updateViews: Record<string, ViewJSON> = {};

        this.views.forEach((view: ViewEntity) => {
            updateViews[view.key.id] = view.dump();
        });

        return updateViews;
    }

    getBounds(positions: PositionSet): Rect | null {
        const rects = this.views
            .map((view: ViewEntity) => {
                const position = positions.find(view.id);
                if (!position) return null;

                return view.getRect(position);
            })
            .filter((rect): rect is NonNullable<typeof rect> => !!rect);

        if (rects.length === 0) {
            return null;
        }

        return rects.reduce((prev, current) => {
            return prev.union(current);
        });
    }

    /**
     * position にヒットする最前面のビューを返します。
     *
     * @param viewPositions ビューのポジション
     * @param position ビューとのヒットを確認したい座標
     */
    findForegroundViewByPosition = (viewPositions: PositionSet, position: Position): ViewEntity | null => {
        for (const view of this.frontToBack().views) {
            const viewPosition = viewPositions.find(view.id);
            if (!viewPosition) continue;

            if (view.getRect(viewPosition).includePosition(position)) {
                return view;
            }
        }

        return null;
    };

    map<U>(fn: (value: ViewEntity, index: number, array: ViewEntity[]) => U): U[] {
        return this.views.map(fn);
    }

    filterByIds(viewIds: ViewId[]): ViewCollection {
        const filteredViews = this.views.filter((view) => viewIds.includes(view.id));

        return new ViewCollection(filteredViews);
    }

    toArray(): ViewEntity[] {
        return [...this.views];
    }
}
