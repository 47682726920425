import { getClientId } from '@framework/app';
import { ViewModelOperationLogSender } from '@model-framework/action-log';
import { RectShape } from '@model-framework/shape';
import { GroupId, StickyZoneId, ViewModelId } from '@schema-common/base';
import { MLAPIOperator } from '@view-model/application/ml-api/MLAPIOperator';
import { MLAPIPayload } from '@view-model/application/ml-api/MLAPIPayload';
import { StickyZoneKey } from '@view-model/domain/key';
import { ViewEntity } from '@view-model/domain/view';
import { Point } from '@view-model/models/common/basic';
import { Rect } from '@view-model/models/common/basic/Rect';
import { ThemeColor } from '@view-model/models/common/color';
import { DraggableContainer } from '@view-model/models/common/components/DraggableContainer';
import { Position } from '@view-model/models/common/types/ui';
import { FontSize } from '@view-model/models/framework/text';
import { memo, useCallback, useMemo } from 'react';
import { useStickyZoneContext } from './adapter';
import { StickyZoneContent, StickyZoneMenu } from './components';
import { StickyZone } from './domain';
import { useNodeEditingUser } from '../editing-users/useNodeEditingUser';
import { useOtherUserIsSelected } from '../client-selected-items/useOtherUserIsSelected';
import { DragContext } from '@model-framework/ui';
import { selectAtom } from 'jotai/utils';
import { dropTargetViewAtom } from '@view-model/adapter/dropTargetViewAtom';
import { useAtomValue } from 'jotai/index';

const MemoizedStickyZoneContent = memo(StickyZoneContent);

type Props = {
    readonly: boolean;
    viewModelId: ViewModelId;
    view: ViewEntity;
    stickyZone: StickyZone;
    position: Point;
    ownerGroupId: GroupId;
    isHoverSelected: boolean;
    isSelected: boolean;
    isMultiSelected: boolean;
    shape: RectShape;
    isLinkableTarget: boolean;
    isMultiSelectionMode: boolean;
    showMenu: boolean;
    showCreatedUser: boolean;
    onClick(id: StickyZoneId): void;
    onDragStart(id: StickyZoneId): void;
    onDrag(context: DragContext): void;
    onDragEnd(context: DragContext): void;
    onEditStart(id: StickyZoneId): void;
    onCreateNodeInZone(position: Position, zoneKey: StickyZoneKey): void;
    onLinkerStart(key: StickyZoneKey, startPosition: Position): void;
    onLinkerMove(currentPosition: Position): void;
    onLinkerEnd(currentPosition: Position): void;
    onDelete(): void;
    onGroupSelectedZone(): void;
    onFontSizeSelected(fontSize: FontSize): void;
    onThemeColorSelected(themeColor: ThemeColor): void;
    onRectSelection(rect: Rect): void;
    onRectSelectionEnd(rect: Rect, zoneId: StickyZoneId): void;
    onAnalysisStart(): void;
    onAnalysisSuccess(payload: MLAPIPayload): void;
    onAnalysisFailure(): void;
    logSender: ViewModelOperationLogSender;
};

const DRAGGER_HEIGHT = 36;

export const StickyZoneView: React.FC<Props> = ({
    readonly,
    viewModelId,
    view,
    stickyZone,
    position,
    ownerGroupId,
    isHoverSelected,
    isSelected,
    isMultiSelected,
    shape,
    isLinkableTarget,
    isMultiSelectionMode,
    showMenu,
    showCreatedUser,
    onClick,
    onDragStart,
    onDrag,
    onDragEnd,
    onEditStart,
    onCreateNodeInZone,
    onLinkerStart,
    onLinkerMove,
    onLinkerEnd,
    onDelete,
    onFontSizeSelected,
    onThemeColorSelected,
    onRectSelection,
    onRectSelectionEnd,
    onGroupSelectedZone,
    onAnalysisStart,
    onAnalysisSuccess,
    onAnalysisFailure,
    logSender,
}: Props) => {
    const clientId = useMemo(() => getClientId(), []);
    const otherUserSelected = useOtherUserIsSelected({
        nodeId: stickyZone.id,
        clientId,
        modelId: view.modelKey.modelID,
    });

    const editingUser = useNodeEditingUser(stickyZone.key);

    const stickyZoneContext = useStickyZoneContext();

    const handleClick = useCallback(() => {
        onClick(stickyZone.id);
    }, [onClick, stickyZone.id]);

    const handleEditStart = useCallback(() => {
        onEditStart(stickyZone.id);
    }, [onEditStart, stickyZone.id]);

    const handleDragStart = useCallback(() => {
        if (readonly) return;
        onDragStart(stickyZone.id);
    }, [onDragStart, readonly, stickyZone.id]);

    const handleDrag = useCallback(
        (context: DragContext): void => {
            if (readonly) return;
            onDrag(context);
        },
        [onDrag, readonly]
    );

    const handleDragEnd = useCallback(
        (context: DragContext): void => {
            if (readonly) return;
            onDragEnd(context);
        },
        [onDragEnd, readonly]
    );

    const handleLinkerStart = useCallback(
        (startPosition: Position): void => {
            onLinkerStart(stickyZone.key, position.add(startPosition));
        },
        [onLinkerStart, position, stickyZone.key]
    );

    const handleLinkerMove = useCallback(
        (currentPosition: Position): void => {
            onLinkerMove(position.add(currentPosition));
        },
        [onLinkerMove, position]
    );

    const handleLinkerEnd = useCallback(
        (currentPosition: Position): void => {
            onLinkerEnd(position.add(currentPosition));
        },
        [onLinkerEnd, position]
    );

    const menuOffset = useMemo(() => {
        const { width } = stickyZone.size.dump();
        return new Point(position.x + width / 2 + 32, position.y);
    }, [stickyZone.size, position]);

    const draggerRectShape = useMemo(
        () =>
            shape.resize({
                height: DRAGGER_HEIGHT,
            }),
        [shape]
    );

    const handleAnalysisSelected = () => {
        MLAPIOperator.chatGPTZoneTitle(viewModelId, view, stickyZone, logSender).then(async (payload) => {
            if (payload === null) {
                onAnalysisFailure();
            } else {
                onAnalysisSuccess(payload);
            }
        });

        onAnalysisStart();
    };

    const isDropTargetAtom = useMemo(
        () =>
            selectAtom(
                dropTargetViewAtom,
                (dropTarget) => dropTarget?.viewId === view.id && dropTarget?.stickyZoneId === stickyZone.id
            ),
        [stickyZone.id, view.id]
    );
    const isDropTarget = useAtomValue(isDropTargetAtom);

    return (
        <>
            <DraggableContainer
                position={position}
                onClick={handleClick}
                onDrag={handleDrag}
                onDragMoveStarted={handleDragStart}
                onDragMoveEnded={handleDragEnd}
                dragHandleContent={
                    isSelected ? (
                        <>
                            <g>
                                {draggerRectShape.render({
                                    className: 'fill-black/10 stroke-black/20',
                                    strokeWidth: 4,
                                    cursor: 'move',
                                })}
                            </g>
                        </>
                    ) : null
                }
                hoveringDragHandleContent={
                    !isSelected && !isMultiSelectionMode ? (
                        <g>
                            {draggerRectShape.render({
                                className: 'fill-black/5 stroke-black/10',
                                strokeWidth: 4,
                                cursor: 'move',
                            })}
                        </g>
                    ) : null
                }
            >
                <MemoizedStickyZoneContent
                    readonly={readonly}
                    stickyZone={stickyZone}
                    position={position}
                    isHoverSelected={isHoverSelected || isDropTarget}
                    isSelected={isSelected}
                    isMultiSelected={isMultiSelected}
                    isLinkableTarget={isLinkableTarget}
                    isMultiSelectionMode={isMultiSelectionMode}
                    showMenu={showMenu}
                    showCreatedUser={showCreatedUser}
                    editing={stickyZoneContext.myEditing}
                    editingUser={editingUser}
                    otherUserSelected={otherUserSelected}
                    onEditStart={handleEditStart}
                    onDblClickZone={onCreateNodeInZone}
                    onLinkerStart={handleLinkerStart}
                    onLinkerMove={handleLinkerMove}
                    onLinkerEnd={handleLinkerEnd}
                    onRectSelection={onRectSelection}
                    onRectSelectionEnd={onRectSelectionEnd}
                    shape={shape}
                />
            </DraggableContainer>
            {showMenu && (
                <StickyZoneMenu
                    groupId={ownerGroupId}
                    currentStyle={stickyZone.style}
                    offset={menuOffset}
                    textEditing={stickyZoneContext.myEditing}
                    onFontSizeSelected={onFontSizeSelected}
                    onThemeColorSelected={onThemeColorSelected}
                    onDeleteZone={onDelete}
                    onGroupSelectedZone={onGroupSelectedZone}
                    onAnalysisSelected={handleAnalysisSelected}
                />
            )}
        </>
    );
};
