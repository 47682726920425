import { ModelType, ModelNamespace, StickyModel, ModelElementPositionMap } from '@view-model/domain/model';
import { NodeCollection, NodeEntityOperation, StickyNode } from '@view-model/models/sticky/StickyNodeView';
import { LinkCollection, LinkEntity, LinkEntityOperation } from '@view-model/models/sticky/StickyLink';
import {
    StickyZone,
    StickyZoneCollection,
    StickyZoneEntityOperation,
    StickyZoneContents,
} from '@view-model/models/sticky/StickyZoneView';
import { StickyZoneJSON } from '@schema-app/view-model/contents/{viewModelId}/model-contents/{modelId}/sticky-zones/{stickyZoneKey}/StickyZoneJSON';
import { ModelElementId, ModelKey, NodeKey } from '@view-model/domain/key';
import { ModelCommentContents, ModelCommentContentsJSON } from '@view-model/models/sticky/ModelComment';
import { UserKey } from '@user/domain';
import { IPositionSetRepository, PositionSet, PositionSetJSON } from '@view-model/models/common/PositionSet';
import { CompositeCommand, ICommand } from '@model-framework/command';
import {
    DescriptionPanelCollectionContents,
    DescriptionPanelCollectionContentsJSON,
} from '@view-model/models/sticky/DescriptionPanel';
import {
    StickyNodeDescriptionSet,
    StickyNodeDescriptionSetJSON,
} from '@view-model/models/sticky/StickyNodeDescription';
import { StickyModelCascadeRepository } from '@view-model/infrastructure/view-model/cascade/StickyModelCascadeRepository';
import { ElementsCreateCommand } from '@view-model/command/basic-model/ElementsCreateCommand';
import { DisplayOrderTree, DisplayOrderTreeJSON } from '@model-framework/display-order';
import { ElementsMoveCommand } from '@view-model/command/basic-model/ElementsMoveCommand/ElementsMoveCommand';
import { LinkJSON } from '@schema-app/view-model/contents/{viewModelId}/model-contents/{modelId}/links/{linkId}/LinkJSON';
import { StickyNodeJSON } from '@schema-app/view-model/contents/{viewModelId}/model-contents/{modelId}/nodes/{nodeId}/StickyNodeJSON';
import { ModelId, StickyZoneId, ViewModelId } from '@schema-common/base';
import { DisplayOrderRepository } from '@model-framework/display-order/infrastructure';
import { StickyModelElementPositionMapRepository } from '@view-model/infrastructure/basic-model/StickyModelElementPositionMapRepository';
import { AssetUrlMap, ViewModelEntity } from '@view-model/domain/view-model';
import { Point, Rect, Size } from '@view-model/models/common/basic';
import { CommentConstants } from '@view-model/models/sticky/ModelComment/constants';

export type StickyModelContentsJSON = {
    key: string;
    type: string;
    namespace: string;
    version: number;
    nodes: StickyNodeJSON[];
    links: LinkJSON[];
    zones: StickyZoneJSON[];
    zonePositions: PositionSetJSON;
    comments: ModelCommentContentsJSON;
    descriptionPanels: DescriptionPanelCollectionContentsJSON;
    nodeDescriptions: StickyNodeDescriptionSetJSON;
    displayOrderTree: DisplayOrderTreeJSON;
};

type LoadableContentsJSON = {
    key: string;
    type: string;
    namespace: string;
    version: number;
    nodes: StickyNodeJSON[];
    links: LinkJSON[];
    zones: StickyZoneJSON[];
    zonePositions: PositionSetJSON;
    comments: ModelCommentContentsJSON;
    descriptionPanels?: DescriptionPanelCollectionContentsJSON | null;
    nodeDescriptions: StickyNodeDescriptionSetJSON | undefined;
    displayOrderTree: DisplayOrderTreeJSON | null;
};

export class StickyModelContents {
    public readonly type: ModelType = ModelType.Sticky;
    public readonly namespace: ModelNamespace = ModelNamespace.Balus;

    private constructor(
        public readonly key: ModelKey,
        private readonly version: number,
        private readonly nodes: NodeCollection,
        private readonly links: LinkCollection,
        private readonly zones: StickyZoneContents,
        private readonly comments: ModelCommentContents,
        private readonly descriptionPanels: DescriptionPanelCollectionContents,
        private readonly nodeDescriptions: StickyNodeDescriptionSet,
        private readonly displayOrderTree: DisplayOrderTree
    ) {}

    /**
     * 要素の集まりから、モデルとして不整合のないコンテンツオブジェクトを作成して返します。
     *
     * 特に、渡された要素で完結しないリンク要素があった場合、そのリンクは返されるコンテンツオブジェクトからは除外され、
     * コンテンツオブジェクトとは別に戻り値として返されます。
     * @param key
     * @param version
     * @param nodes
     * @param links
     * @param zones
     * @param zonePositions
     * @param comments
     * @param descriptionPanels
     * @param nodeDescriptions
     * @param displayOrderTree
     */
    static fromFragments(
        key: ModelKey,
        version: number,
        nodes: NodeCollection,
        links: LinkCollection,
        zones: StickyZoneCollection,
        zonePositions: PositionSet,
        comments: ModelCommentContents,
        descriptionPanels: DescriptionPanelCollectionContents,
        nodeDescriptions: StickyNodeDescriptionSet,
        displayOrderTree: DisplayOrderTree
    ): { contents: StickyModelContents; excludedLinks: LinkCollection } {
        // 始点・終点が渡されたnodes, zones に接続できるリンクだけを抽出
        const nodeIds = nodes.ids();
        const zoneIds = zones.ids();
        const nodeZoneIds = [...nodeIds, ...zoneIds];
        const [validLinks, excludedLinks] = links.splitByConnectivity(nodeZoneIds);

        // nodes, zones で構成されるDisplayOrderTree を引数のdisplayOrderTree から構築する
        // また、引数のdisplayOrderTreeに含まれていないノード、ゾーンは追加して不整合にならないようにする
        const partialTree = displayOrderTree
            .partialTree(nodeZoneIds)
            .addNodesNotIncluded(nodeIds)
            .addZonesNotIncluded(zoneIds);

        const contents = new StickyModelContents(
            key,
            version,
            nodes,
            validLinks,
            new StickyZoneContents(zones, zonePositions),
            comments,
            descriptionPanels,
            nodeDescriptions,
            partialTree
        );

        return { contents, excludedLinks };
    }

    /**
     * モデルコンテンツの複製 (新しい識別子を発行して置き換えたもの) を返す。
     */
    cloneNew(assetUrlMap: AssetUrlMap): StickyModelContents {
        const [newNodeCollection, nodeKeyMap] = this.nodes.cloneNew();
        const [newZones, zoneKeyMap, zoneIdMap] = this.zones.cloneNew();
        const newLinks = this.links.cloneNew({ ...nodeKeyMap, ...zoneKeyMap });

        const nodeIdMap = Object.fromEntries(
            Object.entries(nodeKeyMap).map(([s, k]) => [new NodeKey(s).id.toString(), k.id.toString()])
        );

        const newModelKey = ModelKey.buildNew();

        return new StickyModelContents(
            newModelKey,
            this.version,
            newNodeCollection,
            newLinks,
            newZones,
            this.comments.cloneNew(newModelKey),
            this.descriptionPanels.cloneNew(assetUrlMap),
            this.nodeDescriptions.cloneNew(nodeKeyMap),
            this.displayOrderTree.cloneNew({ ...nodeIdMap, ...zoneIdMap })
        );
    }

    updateNodeZoneCreatedUserKey(userKey: UserKey): StickyModelContents {
        return new StickyModelContents(
            this.key,
            this.version,
            this.nodes.withCreatedUserKey(userKey),
            this.links,
            this.zones.withCreatedUserKey(userKey),
            this.comments,
            this.descriptionPanels,
            this.nodeDescriptions,
            this.displayOrderTree
        );
    }

    dump(): StickyModelContentsJSON {
        const {
            key,
            type,
            namespace,
            nodes,
            links,
            zones,
            version,
            comments,
            descriptionPanels,
            nodeDescriptions,
            displayOrderTree,
        } = this;

        return {
            key: key.toString(),
            type: type.toString(),
            namespace: namespace.toString(),
            nodes: nodes.dump(),
            links: links.dump(),
            zones: zones.zones.dump(),
            zonePositions: zones.positions.dump(),
            version,
            comments: comments.dump(),
            descriptionPanels: descriptionPanels.dump(),
            nodeDescriptions: nodeDescriptions.dump(),
            displayOrderTree: displayOrderTree.dump(),
        };
    }

    static load(dump: LoadableContentsJSON): StickyModelContents {
        const {
            key,
            type,
            namespace,
            version,
            nodes,
            links,
            zones,
            zonePositions,
            comments,
            descriptionPanels,
            nodeDescriptions,
            displayOrderTree,
        } = dump;

        if (
            (ModelType.Basic.toString() !== type && ModelType.Sticky.toString() !== type) ||
            ModelNamespace.Balus.toString() !== namespace
        ) {
            throw new Error(`Invalid type or namespace (type=${type}, namespace=${namespace}) in StickyModelContents`);
        }

        const modelKey = new ModelKey(key);
        const zoneCollection = StickyZoneCollection.load(zones || []);

        // 渡ってきた引数が信用できる（整合性が取れている）とは限らないので直接コンストラクタを呼ばない
        const { contents } = this.fromFragments(
            modelKey,
            version,
            NodeCollection.load(nodes || []),
            LinkCollection.load(links || []),
            zoneCollection,
            PositionSet.load(zonePositions || {}),
            comments ? ModelCommentContents.load(comments) : ModelCommentContents.buildEmpty(modelKey),
            DescriptionPanelCollectionContents.load(descriptionPanels || {}),
            StickyNodeDescriptionSet.load(nodeDescriptions || {}),
            displayOrderTree ? DisplayOrderTree.load(displayOrderTree) : new DisplayOrderTree()
        );

        return contents;
    }

    static buildEmpty(key: ModelKey, version: number): StickyModelContents {
        return new this(
            key,
            version,
            new NodeCollection(),
            new LinkCollection([]),
            StickyZoneContents.buildEmpty(),
            ModelCommentContents.buildEmpty(key),
            DescriptionPanelCollectionContents.buildEmpty(),
            StickyNodeDescriptionSet.fromArray([]),
            new DisplayOrderTree()
        );
    }

    modelEntity(): StickyModel {
        const { key, version } = this;
        return new StickyModel({
            key,
            version,
            nodes: [],
            links: [],
            zones: [],
            zonePositions: new PositionSet(),
        });
    }

    nodeEntities(): StickyNode[] {
        return this.nodes.entities();
    }

    linkEntities(): LinkEntity[] {
        return this.links.entities();
    }

    zoneEntities(): StickyZone[] {
        return [...this.zones.zones.models];
    }

    zonePositionSet(): PositionSet {
        return this.zones.positions;
    }

    commentContents(): ModelCommentContents {
        return this.comments;
    }

    async saveTo(repository: StickyModelCascadeRepository): Promise<void> {
        return repository.saveContents(
            this.modelEntity(),
            this.nodeEntities(),
            this.linkEntities(),
            this.zoneEntities(),
            this.zonePositionSet(),
            this.commentContents(),
            this.descriptionPanels,
            this.nodeDescriptions,
            this.displayOrderTree
        );
    }

    /**
     * このモデルに含まれる要素の識別子(id)の一覧
     */
    elementIds(): ModelElementId[] {
        return [
            ...this.nodes.ids(),
            ...this.links.ids(),
            ...this.zones.allIds(),
            ...this.comments.allIds(),
            ...this.descriptionPanels.allIds(),
        ];
    }

    isEqual(other: StickyModelContents): boolean {
        return (
            other instanceof StickyModelContents &&
            this.key.isEqual(other.key) &&
            this.version === other.version &&
            this.nodes.isEqual(other.nodes) &&
            this.links.isEqual(other.links) &&
            this.zones.isEqual(other.zones) &&
            this.comments.isEqual(other.comments) &&
            this.descriptionPanels.isEqual(other.descriptionPanels) &&
            this.displayOrderTree.isEqual(other.displayOrderTree)
        );
    }

    isEmpty(): boolean {
        const count =
            this.nodes.length +
            this.links.length +
            this.zones.count() +
            this.comments.count() +
            this.descriptionPanels.count() +
            this.nodeDescriptions.size;

        return count === 0;
    }

    move(dx: number, dy: number): StickyModelContents {
        return new StickyModelContents(
            this.key,
            this.version,
            this.nodes.move(dx, dy),
            this.links,
            this.zones.move(dx, dy),
            this.comments.move(dx, dy),
            this.descriptionPanels.move(dx, dy),
            this.nodeDescriptions,
            this.displayOrderTree
        );
    }

    buildDescriptionPanelsCreateCommand(viewModelId: ViewModelId, modelId: ModelId): ICommand {
        return this.descriptionPanels.buildCreateCommand(viewModelId, modelId);
    }

    buildElementsCreateCommand(
        viewModel: ViewModelEntity,
        model: StickyModel,
        nodeEntityOperation: NodeEntityOperation,
        linkEntityOperation: LinkEntityOperation,
        zoneEntityOperation: StickyZoneEntityOperation,
        displayOrderRepository: DisplayOrderRepository,
        parentZoneId?: StickyZoneId
    ): ICommand {
        return new ElementsCreateCommand(
            viewModel,
            model,
            nodeEntityOperation,
            linkEntityOperation,
            zoneEntityOperation,
            this.nodes,
            this.linkEntities(),
            this.zoneEntities(),
            this.zonePositionSet(),
            this.nodeDescriptions,
            this.displayOrderTree,
            displayOrderRepository,
            parentZoneId
        );
    }

    buildMoveCommand(
        dx: number,
        dy: number,
        positionMapRepository: StickyModelElementPositionMapRepository,
        commentPositionSetRepository: IPositionSetRepository,
        descriptionPanelPositionSetRepository: IPositionSetRepository
    ): ICommand | null {
        const moveNodeZoneCommand = this.buildMoveNodeZoneCommand(dx, dy, positionMapRepository);
        const moveCommentCommand = this.comments.buildMoveCommand(dx, dy, commentPositionSetRepository);
        const moveDescriptionPanelCommand = this.descriptionPanels.buildMoveCommand(
            dx,
            dy,
            descriptionPanelPositionSetRepository
        );

        return CompositeCommand.composeOptionalCommands(
            moveNodeZoneCommand,
            moveCommentCommand,
            moveDescriptionPanelCommand
        );
    }

    private buildMoveNodeZoneCommand(
        dx: number,
        dy: number,
        positionMapRepository: StickyModelElementPositionMapRepository
    ): ICommand | null {
        const { nodes, zones } = this;
        const zonePositions = zones.positions;

        if (zonePositions.isEmpty() && nodes.isEmpty()) return null;

        const positions = new ModelElementPositionMap(nodes.positionSet(), zonePositions);

        return new ElementsMoveCommand(positions, positions.movePosition(dx, dy), positionMapRepository);
    }

    /**
     * 外部向けに付箋のテキストを文字列として出力します。
     */
    exportNodesText(): string {
        return this.nodes.exportTexts();
    }

    /**
     * 付箋モデル中に含まれるview-model-assetsのURL一覧
     */
    assetUrls(): string[] {
        const urls = [...this.descriptionPanels.assetUrls()];
        return Array.from(new Set(urls));
    }

    getUnionRect(): Rect | null {
        const { nodes, zones, descriptionPanels, comments } = this;
        if (nodes.isEmpty() && zones.count() === 0 && descriptionPanels.count() === 0 && comments.count() === 0)
            return null;

        // ふせんかゾーンがある場合には、ふせんorゾーンのみで矩形を作る
        if (!nodes.isEmpty() || !(zones.count() === 0)) {
            const rects = [
                ...nodes.map((node) => node.getRect()),
                ...zones.map(([zone, position]) => {
                    return zone.getRect(position);
                }),
            ];

            return rects.reduce((result, rect) => result.union(rect), rects[0]);
        }

        const descriptionPanelRects = descriptionPanels
            .allIds()
            .map((panelId) => descriptionPanels.getRect(panelId))
            .filter((rect): rect is Rect => !!rect);
        const commentRects = comments
            .allIds()
            .map((commentId) => comments.getPosition(commentId))
            .filter((point): point is Point => !!point)
            .map((point) => new Rect(point, new Size(CommentConstants.UISetting.width, 96))); // コメントはサイズを保存していないので、貼り付け用の矩形を指定するために仮の高さを指定
        const rects = [...descriptionPanelRects, ...commentRects];
        if (rects.length <= 0) return null;

        return rects.reduce((result, rect) => result.union(rect), rects[0]);
    }
}
